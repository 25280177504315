<template>
  <div class="slider_courses_block">
    <div class="slider_courses_container">
      <div class="news_slider_arrow_left" @click="prevPage"/>
      <div class="news_slider_arrow_right" @click="nextPage"/>
      <div class="slider_courses_label">{{ $t('New_courses') }}</div>
      <carousel v-if="newCourses.length" :per-page="1" navigationNextLabel="next" paginationActiveColor="#305AA6" paginationColor="#DEE9FF"
                :navigateTo="manualNavigation" class="carousel_slider_home">
        <slide v-for="course in newCourses">
          <div class="slider_item">
            <div class="slider_item_text">
              <div class="slider_item_text_desc">
                <div class="slider_item_text_desc_english">
                  {{ course.title }}
                </div>
                <div class="slider_english_bar">
                  <div class="slider_course_info">
                    <div class="slider_course_lang">{{ course.language }}</div>
<!--                    <div class="slider_course_difficulty">Легкий</div>-->
<!--                    <a class="slider_course_link">Поделиться</a>-->
                    <div class="slider_course_rating">{{+course.avg_rating.toFixed(1) ?? 0}}</div>
                  </div>
                  <div class="slider_course_info_text" v-html="course.short_detail"></div>
                </div>
                <div class="slider_english_published">
                  {{ $t('published') }}: {{ formatDate(course.created_at) }}
                </div>
                <div class="slider_course_records">
                  {{ course.enrollments }} {{$t('write_on_course')}}
                </div>
              </div>
              <div class="slider_button_container">
                <span v-if="logged">
                  <router-link
                    class="slider_button_record"
                    v-if="dataIsCompleted(course) && course.registered"
                    :to="{name: 'about-course', params: {slug: course.slug}}"
                  >
                    <span>{{$t('Proceed')}}</span>
                  </router-link>
                  <button v-else class="slider_button_record" @click="enrollment(course)"><span>{{$t('Sign_up')}}</span></button>
                </span>
                <router-link
                  v-else
                  :to="'/signin?redirect=/course/' + course.slug"
                  class="slider_button_record"
                ><span>{{$t('Sign_up')}}</span></router-link>

                <router-link
                  :to="{ name: 'course-details', params: { slug: course.slug } }"
                  class="slider_button_more"
                >
                  <span>{{$t('more')}}</span>
                </router-link>
              </div>
            </div>
            <div class="slider_item_img_container">
              <div
                class="slider_item_img"
                style="border-radius: 10px"
                :style="'background-image: url(\'' + course.preview_image + '\')'"
              >
                <div class="slider_course_rating_img">4.9</div>
                <div class="slider_course_rating_star_img" />
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
    <el-dialog
      :title="$t('Sign_up')"
      :visible.sync="modalVisible"
      width="50%"
      :before-close="handleClose">
      <div class="w-100">
        <el-form ref="form" :model="registerForm">
          <div class="row">
            <div class="col-md-6">
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Фамилия"
                  v-model="registerForm.last_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Имя"
                  v-model="registerForm.first_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Электронная почта"
                  data-required="email"
                  data-invalid-message="Пожалуйста введите корректный email"
                  v-model="registerForm.email"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.region_id"
                  placeholder="Выберите область"
                  @change="getCities"
                >
                  <el-option
                    v-for="state in states"
                    :label="state.name"
                    :value="state.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.district_id"
                  placeholder="Выберите район"
                  @change="getDistricts"
                >
                  <el-option
                    v-for="city in cities"
                    :label="city.name"
                    :value="city.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.address"
                  placeholder="Выберите населенный пункт"
                >
                  <el-option
                    v-for="district in districts"
                    :label="district.name"
                    :value="district.name"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div class="col-md-6">
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Место работы"
                  v-model="registerForm.location_work"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Должность"
                  v-model="registerForm.position"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  class="w-100"
                  v-model="registerForm.education"
                  placeholder="Выберите уровень образования"
                >
                  <el-option label="Высшее" value="high"></el-option>
                  <el-option label="Среднее" value="medium"></el-option>
                  <el-option label="Среднееспециальное" value="specialized"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Наименование учебного заведения"
                  v-model="registerForm.education_name"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="Специализация"
                  v-model="registerForm.specialization"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  autocomplete="nope"
                  placeholder="О себе (необязательно)"
                  v-model="registerForm.about"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer float-left">
        <el-button type="primary" @click="updateProfile">{{$t('Sign_up')}}</el-button>
        <el-button @click="modalVisible = false">Отменить</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="successModalVisible"
      width="50%"
      :before-close="successHandleClose">
      <div class="text-center">
        <svg class="mb-4" width="172" height="171" viewBox="0 0 172 171" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M115.391 69.4688L76.2029 106.875L56.6094 88.1719" stroke="white"
                stroke-width="7.22" stroke-linecap="round" stroke-linejoin="round"/>
          <path
            d="M86 149.625C121.415 149.625 150.125 120.915 150.125 85.5C150.125 50.0847 121.415 21.375 86 21.375C50.5847 21.375 21.875 50.0847 21.875 85.5C21.875 120.915 50.5847 149.625 86 149.625Z"
            stroke="white" stroke-width="7.22" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <p class="my-40 bold fs30 c_white text-center">Ваша запись успешно оформлена</p>
        <p class="br-10 bold fs20 c_white text-center py-28 px-24 bg_primary_dark">На почту вам придет
          дополнительное уведомление </p>

        <el-button
          class="my-4"
          type="text"
          @click="$router.push({ name: 'about-course', params: { slug: courseSlug } })"
        >
          Перейти в курс
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { Carousel, Slide } from 'vue-carousel';
import Vue from "vue";
import Profile from "@/models/profile";
import RegisterForm from "@/models/register";
export default {
  name: 'SliderHome',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      courseId: 0,
      courseSlug: '',
      states: [],
      cities: [],
      districts: [],
      modalVisible: false,
      successModalVisible: false,
      manualNavigation : 0,
      newCourses: [],
      registerForm: new RegisterForm({}),
    }
  },
  mounted() {
    this.getNewCourses();
  },
  methods: {
    enrollment(course) {
      let apiRoot = API_ROOT
      this.courseId = course.id
      this.courseSlug = course.slug
      this.$http.post(`${apiRoot}/api/course/enroll/${course.slug}`)
        .then(res => {
          this.successModalVisible = true
        })
    },
    openRegisterForm(id, slug) {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/user`)
        .then(res => {
          this.registerForm = new RegisterForm(res.body ? res.body : {})
        })
      this.courseId = id
      this.courseSlug = slug
      this.getStates()
      this.modalVisible = true
    },
    handleClose() {
      this.modalVisible = false;
    },
    successHandleClose() {
      this.successModalVisible = true;
    },
    getStates() {
      this.registerForm.region_id = null;
      this.registerForm.district_id = null;
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/states`)
        .then(res => {
          if (res.data) {
            this.states = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    getCities(stateId) {
      this.registerForm.district_id = null;
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/cities/${stateId}`)
        .then(res => {
          if (res.data) {
            this.cities = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    getDistricts(cityId) {
      this.registerForm.address = '';
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/references/districts/${cityId}`)
        .then(res => {
          if (res.data) {
            this.districts = res.data.data
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
        })
    },
    formatDate(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    updateProfile() {
      let apiRoot = window.API_ROOT;
      this.$http.post(`${apiRoot}/api/course/update-profile`, {
        ...this.registerForm,
        course_id: this.courseId,
      })
        .then(res => {
          if (res.data) {
            this.modalVisible = false;
            this.successModalVisible = true;
          }
        })
        .catch(err => {
          Vue.toastr({
            message: 'Ошибка',
            description: 'Не правильно введены данные',
            type: 'error'
          })
        })
        .finally(() => {
        })
    },
    getNewCourses() {
      let apiRoot = API_ROOT
      this.$http.get(`${apiRoot}/api/courses?on_index=true`)
        .then(res => {
          this.newCourses = res.body.data
        })
    },
    nextPage() {
      if (this.manualNavigation >= this.newCourses.length - 1) {
        this.manualNavigation = 0
      } else this.manualNavigation = this.manualNavigation + 1
    },
    prevPage() {
      if (this.manualNavigation <= 0) {
        this.manualNavigation = this.newCourses.length - 1
      } else this.manualNavigation = this.manualNavigation - 1
    },
    dataIsCompleted (course) {
      /**
       * временно отключаем заполнение профиля когда она данные не заполнены
       */
      return true;

      if (!course) return false;
      let user = course.user
      let completed = false
      if (
        user.first_name
        && user.last_name
        && user.email
        && user.address
        && user.location_work
        && user.position
        && user.region_id
        && user.district_id
        && user.about
        && user.specialization
        && user.education
        && user.education_name
      ) completed = true
      return completed
    },
  },
  computed: {
    logged: function () {
      let token = localStorage.getItem('token')
      return token !== null && token !== ''
    }
  }
}
</script>

<style scoped>

</style>
