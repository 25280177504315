<template>
  <div class="slider_block">
    <div class="slider_container">
      <div class="slider_arrow_left" @click="prevPage"/>
      <div class="slider_arrow_right" @click="nextPage"/>
      <carousel
        :per-page="1"
        navigationNextLabel="next"
        paginationActiveColor="#FFFFFF"
        paginationColor="rgba(255, 255, 255, 0.17)"
        :navigateTo="manualNavigation"
      >
        <slide class="slider_slide">
          <div class="slider_label">
            Birge Oqý
          </div>
          <div class="slider_text">
            <span class="crop-height" v-html="$t('main_slider_1')"></span>
          </div>
          <a href="/courses" class="slider_enroll">
            <span>{{ $t('Sign_up_for_a_courses') }}</span>
          </a>
        </slide>
        <slide class="slider_slide">
          <div class="slider_label">
            Birge Oqý
          </div>
          <div class="slider_text crop-height">
            <span class="crop-height" v-html="$t('main_slider_2')"></span>
          </div>
          <a href="/courses" class="slider_enroll">
            <span>{{ $t('Sign_up_for_a_courses') }}</span>
          </a>
        </slide>
        <slide class="slider_slide">
          <div class="slider_label">
            Birge Oqý
          </div>
          <div class="slider_text crop-height">
            <span class="crop-height" v-html="$t('main_slider_3')"></span>
          </div>
          <a href="/courses" class="slider_enroll">
            <span>{{ $t('Sign_up_for_a_courses') }}</span>
          </a>
        </slide>
      </carousel>
    </div>

  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
  name: "SliderMainHome",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      manualNavigation: 0
    }
  },
  methods: {
    nextPage() {
      if (this.manualNavigation >= 2) {
        this.manualNavigation = 0
      } else this.manualNavigation = this.manualNavigation + 1
    },
    prevPage() {
      if (this.manualNavigation <= 0) {
        this.manualNavigation = 2
      } else this.manualNavigation = this.manualNavigation - 1
    },
  }
}
</script>

<style scoped>
.crop-height {
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
  overflow-y: hidden !important;
}
</style>
