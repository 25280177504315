<template>
  <div class="index_block">
    <SliderMainHome />
    <SliderHome />
    <div class="online_store_block">
      <div class="online_store_container">
        <div class="online_store_label">
          {{ $t("Shop_online") }}
        </div>
        <div class="online_store_goods">
          <div class="online_store_item" v-for="item in storeActiveGoods">
            <div class="online_store_item_img_container">
              <!--              <div class="online_store_item_img"/>-->
              <img
                :src="backUrl + mainImage(item)"
                class="online_store_item_img"
                alt="gadget"
                @error="setAltImg"
              />
            </div>
            <div class="online_store_item_desc">
              {{
                $i18n.locale === "ru"
                  ? item.translations[0].name
                  : item.translations[1].name
              }}
            </div>
            <div class="online_store_goods_available">
              {{ $t("Available_items") }}: {{ item.count }}
            </div>
            <div class="online_store_buy_block">
              <div class="online_store_price">
                {{ item.price }}
              </div>
              <router-link :to="/store/ + item.id" class="online_store_buy">
                <span>{{ $t("Buy") }}</span>
              </router-link>
            </div>
          </div>
        </div>
        <router-link to="/store" class="online_store_all_goods">
          <span>{{ $t("All_goods") }}</span>
        </router-link>
      </div>
    </div>
    <div class="tariff_block">
      <div class="tariff_container">
        <div class="tariff_label">
          {{ $t("Tariff") }}
        </div>
        <div class="tariff_goods">
          <div class="tariff_item">
            <div class="tariff_title">{{ $t("Tariff_1") }}</div>
            <div class="tariff_price">₸ {{ $t("Free") }}</div>
            <div class="tariff_description">
              {{ $t("Tariff_1_description") }}
            </div>
            <button @click="$bvModal.show('tariff1')" class="tariff_buy">
              <span>{{ $t("Details") }}</span>
            </button>
            <b-modal
              id="tariff1"
              centered
              class="tariff-details-modal"
              hide-footer
              hide-header
              size="md"
            >
              <div class="tariff-content" id="dialog">
                <button
                  @click="$bvModal.hide('tariff1')"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/svg/close.svg" alt="close" width="24" />
                  <span aria-hidden="true"></span>
                </button>
                <div class="tariff-modal-title">{{ $t("Tariff_1") }}</div>
                <div class="tariff-modal-subtitle">{{ $t("Free") }}</div>
                <div class="tariff-modal-description">
                  {{ $t("Tariff_1_content") }}
                </div>
                <div class="tariff-modal-footer">
                  <label>{{ $t("Contacts") }}:</label>
                  <span class="contact-info">support@birgeoqy.kz</span>
                  <span class="contact-info">8 727 310 02 58</span>
                </div>
              </div>
            </b-modal>
          </div>
          <div class="tariff_item">
            <div class="tariff_title">{{ $t("Tariff_2") }}</div>
            <div class="tariff_price">₸ 5 500</div>

            <div class="tariff_description">
              {{ $t("Tariff_2_description") }}
            </div>
            <button @click="$bvModal.show('tariff2')" class="tariff_buy">
              <span>{{ $t("Details") }}</span>
            </button>
            <b-modal
              id="tariff2"
              centered
              class="tariff-details-modal"
              hide-footer
              hide-header
              size="md"
            >
              <div class="tariff-content" id="dialog">
                <button
                  @click="$bvModal.hide('tariff2')"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/svg/close.svg" alt="close" width="24" />
                  <span aria-hidden="true"></span>
                </button>
                <div class="tariff-modal-title">{{ $t("Tariff_2") }}</div>
                <div class="tariff-modal-subtitle">₸ 5 500</div>
                <div class="tariff-modal-description">
                  {{ $t("Tariff_2_content") }}
                </div>
                <div class="tariff-modal-footer">
                  <label>{{ $t("Contacts") }}:</label>
                  <span class="contact-info">support@birgeoqy.kz</span>
                  <span class="contact-info">8 727 310 02 58</span>
                </div>
                <button
                  class="tariff-registration"
                  @click="
                    $router.push({ name: 'signup', params: { tariff: '2' } })
                  "
                >
                  {{ $t("Registration") }}
                </button>
              </div>
            </b-modal>
          </div>
          <div class="tariff_item">
            <div class="tariff_title">{{ $t("Tariff_3") }}</div>
            <div class="tariff_price">₸ 230 000</div>
            <div class="tariff_description">
              {{ $t("Tariff_3_description") }}
            </div>
            <button @click="$bvModal.show('tariff3')" class="tariff_buy">
              <span>{{ $t("Details") }}</span>
            </button>
            <b-modal
              id="tariff3"
              centered
              class="tariff-details-modal"
              hide-footer
              hide-header
              size="md"
            >
              <div class="tariff-content" id="dialog">
                <button
                  @click="$bvModal.hide('tariff3')"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="/svg/close.svg" alt="close" width="24" />
                  <span aria-hidden="true"></span>
                </button>
                <div class="tariff-modal-title">{{ $t("Tariff_3") }}</div>
                <div class="tariff-modal-subtitle">₸ 230 000</div>
                <div class="tariff-modal-description">
                  {{ $t("Tariff_3_content") }}
                </div>
                <div class="tariff-modal-footer">
                  <label>{{ $t("Contacts") }}:</label>
                  <span class="contact-info">support@birgeoqy.kz</span>
                  <span class="contact-info">8 727 310 02 58</span>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="online_school_block">
      <div class="online_school_container">
        <div class="online_school_desc">
          <div class="online_school_text">
            <div class="online_school_name">Smart Ustaz</div>
            <div class="online_school_course">{{ $t("platform") }}</div>
            <a
              href="https://smartustaz.kz/"
              class="online_school_button"
              target="_blank"
            >
              {{ $t("Go") }}
            </a>
            <!--            <a href="" class="online_school_button"><span class="online_school_btn_name">Перейти</span></a>-->
          </div>
          <div class="school_logo_container" />
        </div>
      </div>
    </div>
    <div class="projects_block">
      <div class="projects_container">
        <div class="projects_label">{{ $t("Our_projects") }}</div>
        <div class="projects_list">
          <!--
           <a
            href="https://qabilet.elbasyacademy.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/QABILET.svg"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("QABILET") }}</div>
          </a>
          -->
          <a
            href="https://ro.elumiti.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/talent.png"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("talent") }}</div>
          </a>

          <a
            href="https://1000bala.elumiti.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/1000-BALA-logo.png"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("1000bala") }}</div>
          </a>

          <!--
           <a
            href="https://medal.elbasyacademy.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/elbasy_medali.svg"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("Medal_of_Elbasy") }}</div>
          </a>
          -->

          <a
            href="https://premia.elumiti.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/elumiti_premia.png"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("elumiti_premia") }}</div>
          </a>

          <a
            href="https://smartustaz.kz/"
            class="projects_item"
            target="_blank"
          >
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/ustaz.png"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">{{ $t("Smart_ustaz") }}</div>
          </a>

          <a href="https://kzgame.kz/" class="projects_item" target="_blank">
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/era.svg"
                class="projects_item_logo"
                style="height: 180px;margin-top: -30px;"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">
              {{ $t("Era_of_Independence") }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="partners_block">
      <div class="partners_container">
        <div class="partners_label">{{ $t("Partners") }}</div>
        <div class="partners_list">
          <a href="https://fnn.kz/ru" target="_blank" class="projects_item mr">
            <div class="projects_item_logo_container">
              <img
                src="@/assets/images/fund_logo.png"
                class="projects_item_logo"
                alt="logo"
              />
            </div>
            <div class="projects_item_text">
              {{ $t("Nursultan_Nazarbayev_Foundation") }}
            </div>
          </a>

          <!--          <a href="http://happychild.kz/" target="_blank" class="projects_item mr">-->
          <!--            <div class="projects_item_logo_container">-->
          <!--              <img src="@/assets/images/happy_child.png" class="projects_item_logo" alt="logo"/>-->
          <!--            </div>-->
          <!--            <div class="projects_item_text">{{ $t('Development_Center_Family_Academy') }}</div>-->
          <!--          </a>-->
          <!--          <a href="http://happychild.kz/" target="_blank" class="projects_item mr">-->
          <!--            <div class="projects_item_logo_container">-->
          <!--              <img src="@/assets/images/happy_child.png" class="projects_item_logo" alt="logo"/>-->
          <!--            </div>-->
          <!--            <div class="projects_item_text">{{ $t('Development_Center_Family_Academy') }}</div>-->
          <!--          </a>-->
          <!--          <a href="http://happychild.kz/" target="_blank" class="projects_item mr">-->
          <!--            <div class="projects_item_logo_container">-->
          <!--              <img src="@/assets/images/happy_child.png" class="projects_item_logo" alt="logo"/>-->
          <!--            </div>-->
          <!--            <div class="projects_item_text">{{ $t('Development_Center_Family_Academy') }}</div>-->
          <!--          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import SliderHome from "@/views/Home/SliderHome";
import SliderMainHome from "@/views/Home/SliderMainHome";
import CountDown from "@/components/CountDown";

export default {
  components: {
    CountDown,
    SliderHome,
    SliderMainHome,
    Carousel,
    Slide,
  },
  data() {
    return {
      showFilter: false,
      storeActiveData: {},
      storeActiveGoods: [],
      backUrl: window.API_ROOT + "/images/goods/",
      noPhoto: require("@/assets/images/no_photo.png"),
    };
  },
  methods: {
    shopGoodsActive() {
      this.$http
        .get(API_ROOT + `/api/shop/goods?on_index=true`)
        .then((res) => {
          this.storeActiveData = res.data;
          this.storeActiveGoods = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setAltImg(event) {
      event.target.src = this.noPhoto;
    },
    mainImage(item) {
      for (let i = 0; i < item.gallery.length; i++) {
        if (item.gallery[i].is_main === 1) {
          return item.gallery[i].image;
        }
      }
    },
  },
  computed: {
    mode() {
      switch (window.location.hostname) {
        case "localhost":
          return "development";
        case "birgeoqu.elumiti.kz":
          return "development";
        case "birgeoqy.kz":
          return "production";
        default:
          return "production";
      }
    },
  },
  mounted() {
    this.shopGoodsActive();
  },
};
</script>
<style>
.btn-result {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  max-width: 252.7px !important;
  margin-top: 1rem !important;
  display: block !important;
}

@media (max-width: 991px) {
  .btn-result {
    margin: auto;
  }
}

.slide-pre,
.slide-next {
  position: absolute;
  top: calc(50% - 25px);
  border: none;
  background-color: transparent;
}

.slide-pre:active,
.slide-pre:hover,
.slide-next:active,
.slide-next:hover {
  outline: none;
  cursor: pointer;
}

.slide-next {
  right: -50px;
}

.slide-pre {
  left: -50px;
}
</style>
