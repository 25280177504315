<template>
  <div class="counter-block">
    <div>{{ $t("new_price") }}</div>
    <div class="counter-block-display"><span>{{ displayDays }}</span> {{ $t("time_d") }} <span>{{ displayHours }}</span> {{ $t("time_h") }}
      <span>{{ displayMinutes }}</span> {{ $t("time_m") }} <span>{{ displaySeconds }}</span> {{ $t("time_s") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CountDown",
  props: ['year', 'month', 'date', 'hour', 'minute'],
  data() {
    return {
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
    }
  },
  computed: {
    _seconds: () => 1000,
    _minutes() {
      return this._seconds * 60
    },
    _hours() {
      return this._minutes * 60
    },
    _days() {
      return this._hours * 24
    },
    end() {
      return new Date(
        this.year,
        this.month - 1,
        this.date,
        this.hour,
        this.minute,
        0,
        0,
      )
    }
  },
  mounted() {
    this.showRemaining()
  },
  methods: {
    formatNum: num => (num < 10 ? "0" + num : num),
    showRemaining() {
      const timer = setInterval(() => {
        const now = new Date()
        const distance = this.end.getTime() - now.getTime()

        if (distance < 0) {
          clearInterval(timer)
           return
        }

        const days = Math.floor(distance / this._days)
        const hours = Math.floor((distance % this._days) / this._hours)
        const minutes = Math.floor((distance % this._hours) / this._minutes)
        const seconds = Math.floor((distance % this._minutes) / this._seconds)

        this.displayMinutes = this.formatNum(minutes)
        this.displaySeconds = this.formatNum(seconds)
        this.displayHours = this.formatNum(hours)
        this.displayDays = this.formatNum(days)

      }, 1000)
    }
  }
}
</script>

<style>

</style>
